[data-v-567ae6d4] .item-time {
  text-align: center;
}
.index[data-v-567ae6d4] {
  height: 100%;
  position: relative;
}
.index .header[data-v-567ae6d4] {
    height: 40px;
    background-color: #0270c1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.index .header .left[data-v-567ae6d4] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
}
.index .header .left .logo[data-v-567ae6d4] {
        display: inline-block;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        background-color: #1f8cf7;
}
.index .header .left .senice[data-v-567ae6d4] {
        color: white;
        font-size: 10px;
        height: 100%;
        line-height: 40px;
        padding-right: 10px;
        background-color: #1f8cf7;
}
.index .header .left .icon[data-v-567ae6d4] {
        color: white;
        width: 100px;
}
.index .header .left .icon i[data-v-567ae6d4] {
          margin: 0 10px;
}
.index .header .left .search[data-v-567ae6d4] {
        width: 400px;
        color: white;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
}
.index .header .left .search .btn[data-v-567ae6d4] {
          color: white;
          background-color: #0267b2;
          font-size: 10px;
          border-right: 1px solid white;
          padding-right: 5px;
          margin-right: 5px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          width: 70px;
}
.index .header .right[data-v-567ae6d4] {
      width: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      color: white;
}
.index .header .right i[data-v-567ae6d4],
      .index .header .right span[data-v-567ae6d4],
      .index .header .right img[data-v-567ae6d4] {
        margin: 0 5px;
}
.index .header .right img[data-v-567ae6d4] {
        border-radius: 50%;
}
.index h2[data-v-567ae6d4] {
    width: 100%;
    height: 70px;
    background-color: white;
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    /*     display: flex;
    justify-content: space-around;
    align-items: center; */
}
.index h2 .el-dropdown[data-v-567ae6d4] {
      margin: 0 0 !important;
}
.index h2 .el-button[data-v-567ae6d4] {
      margin-left: 10px;
}
.index section[data-v-567ae6d4] {
    overflow: auto;
}
